// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, ResolveLinks, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import EvenementDropdown from "./e08FtnXfP";
const EvenementDropdownFonts = getFonts(EvenementDropdown);

const cycleOrder = ["RwsDh0EqP", "aoB_ZT3qE"];

const serializationHash = "framer-1besn"

const variantClassNames = {aoB_ZT3qE: "framer-v-y68vdt", RwsDh0EqP: "framer-v-191byrp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Tablet/Phone": "aoB_ZT3qE", Desktop: "RwsDh0EqP"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "RwsDh0EqP"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "RwsDh0EqP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-191byrp", className, classNames)} data-border data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"RwsDh0EqP"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-ed9e07e0-96e5-4243-a29f-e535303a25a5, rgb(245, 245, 245))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-6af95ce3-cc10-4d84-a3c9-9843318a79a2, rgb(255, 255, 255))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 5px 8px 0px rgba(0, 0, 0, 0.05)", ...style}} variants={{aoB_ZT3qE: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: "none"}}} {...addPropertyOverrides({aoB_ZT3qE: {"data-framer-name": "Tablet/Phone"}}, baseVariant, gestureVariant)}><ResolveLinks links={[{href: {webPageId: "NWQOA5ecH"}, implicitPathVariables: undefined}, {href: {webPageId: "NWQOA5ecH"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider ><motion.div className={"framer-dtrmyb-container"} layoutDependency={layoutDependency} layoutId={"soISHUws9-container"}><EvenementDropdown height={"100%"} HGoxJpwjL={"Veelgestelde vragen"} id={"soISHUws9"} layoutId={"soISHUws9"} PRG4daZ8L={false} variant={"sRoEZwoUQ"} width={"100%"} xA9wAxaZt={resolvedLinks[0]} {...addPropertyOverrides({aoB_ZT3qE: {xA9wAxaZt: resolvedLinks[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {webPageId: "nAaQNnlyq"}, implicitPathVariables: undefined}, {href: {webPageId: "nAaQNnlyq"}, implicitPathVariables: undefined}]}>{(resolvedLinks1) => (
<ComponentViewportProvider ><motion.div className={"framer-wax7ki-container"} layoutDependency={layoutDependency} layoutId={"PJnqv8yyF-container"}><EvenementDropdown height={"100%"} HGoxJpwjL={"Tips en ideeën"} id={"PJnqv8yyF"} layoutId={"PJnqv8yyF"} PRG4daZ8L={false} variant={"sRoEZwoUQ"} width={"100%"} xA9wAxaZt={resolvedLinks1[0]} {...addPropertyOverrides({aoB_ZT3qE: {xA9wAxaZt: resolvedLinks1[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1besn.framer-xjb3yr, .framer-1besn .framer-xjb3yr { display: block; }", ".framer-1besn.framer-191byrp { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 20px; position: relative; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-1besn .framer-dtrmyb-container, .framer-1besn .framer-wax7ki-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1besn.framer-191byrp { gap: 0px; } .framer-1besn.framer-191byrp > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-1besn.framer-191byrp > :first-child { margin-top: 0px; } .framer-1besn.framer-191byrp > :last-child { margin-bottom: 0px; } }", ".framer-1besn.framer-v-y68vdt.framer-191byrp { padding: 0px; }", ".framer-1besn[data-border=\"true\"]::after, .framer-1besn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 104.5
 * @framerIntrinsicWidth 186
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"aoB_ZT3qE":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbcLz5b0Ix: React.ComponentType<Props> = withCSS(Component, css, "framer-1besn") as typeof Component;
export default FramerbcLz5b0Ix;

FramerbcLz5b0Ix.displayName = "Subnav: hoe werkt het";

FramerbcLz5b0Ix.defaultProps = {height: 104.5, width: 186};

addPropertyControls(FramerbcLz5b0Ix, {variant: {options: ["RwsDh0EqP", "aoB_ZT3qE"], optionTitles: ["Desktop", "Tablet/Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerbcLz5b0Ix, [{explicitInter: true, fonts: []}, ...EvenementDropdownFonts], {supportsExplicitInterCodegen: true})